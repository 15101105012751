import React, { useState } from "react";
import Page from "../../layouts/Page";
import {
  Button,
  Card,
  DatePicker,
  Input,
  TimePicker,
  Typography,
  message,
} from "antd";
import Label from "../../components/Label";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../utils/api.utils";
import { useSelector } from "react-redux";
import vd from "../../assets/open_loading.gif";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomTimePicker from "../../components/CustomTimePicker";
import { counselorFillInfoInterKartEvent } from "../../events/interkartEvents";
import { setDateTimeWithToday, transformDate } from "../../utils/helper.utils";

export default function CounselorPersonalInfo() {
  const [loading, setLoading] = useState<boolean>(false);
  const [gotra, setgotra] = useState<string>("");
  const [name, setname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [dob, setDob] = useState<any>("");
  const [tob, setTob] = useState<any>("");
  const [pob, setPob] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  
  const submitForm = async () => {
    if (!location?.state?.id) {
      message.error("No Booking Id Found!");
      return;
    }
    setLoading(true);


    const reqBody = {
      booking_id: location?.state.id,
      userdetails: {
        gotra,
        name,
        ...(dob &&
          dob?.day &&
          dob?.year &&
          dob?.month && { dob: transformDate(dob) }),
        ...(tob &&
          tob?.hour &&
          tob?.minute &&
          tob?.period && { tob: setDateTimeWithToday(tob) }),
        pob,
        phone,
        email,
      },
    };
    setTimeout(() => {
      setLoading(false);
      navigate(`/counselor/${location?.state.id}`, { state: location.state });
    }, 2000);

    await postRequest(`/noauth-api/v1/astro/userInfo`, reqBody)
      .then((_res: any) => {})
      .catch((err: any) => {});
    counselorFillInfoInterKartEvent({
      booking_id: reqBody.booking_id,
      ...reqBody.userdetails,
    });
  };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div>
          <img src={vd} alt="loading..." className="mt-[-200px]" />
        </div>
        <Typography.Text className="text-center block mt-[-150px] text-2xl m-10">
          Finding the expert astrologer for your problems
        </Typography.Text>
      </div>
    );
  }

  return (
    <Page
      footer={
        <div
          className={`flex items-center justify-around p-2 bg-white shadow-s w-full h-[6vh]`}
        >
          <Button
            loading={loading}
            onClick={() => submitForm()}
            size="large"
            className="bg-orange-500 w-full"
            type="primary"
          >
            <Typography.Text className="font-semibold text-white text-xl">
              Continue
            </Typography.Text>
          </Button>
        </div>
      }
    >
      <Card className="w-full h-full">
        <Label>What is your name?</Label>
        <Input
          placeholder="Full Name"
          size="large"
          className="rounded mb-4 mt-2"
          onChange={(e) => setname(e.target.value)}
        />

        <Label>What is your surname/gotra?</Label>
        <Input
          placeholder="Surname/Gotra?"
          size="large"
          className="rounded mb-4 mt-2"
          onChange={(e) => setgotra(e.target.value)}
        />

        <Label>What is your Date Of Birth?</Label>

        <CustomDatePicker
          className="mb-4 mt-2"
          onChange={(e: any) => {
            setDob(e);
            console.log(e);
          }}
        />

        <Label>What is your Time Of Birth?</Label>
        <CustomTimePicker
          className="mb-4 mt-2"
          onChange={(e: any) => {
            setTob(e);
          }}
        />
        {/* <TimePicker
          size="large"
          placeholder="Time Of Birth"
          className="rounded mb-4 mt-2 block"
          onChange={(e: any) => setTob(e)}
        /> */}

        <Label>What is your Place Of Birth?</Label>
        <Input
          placeholder="Place Of Birth"
          size="large"
          className="rounded mb-4 mt-2"
          onChange={(e: any) => setPob(e.target.value)}
        />

        <Label>What is your email?</Label>
        <Input
          placeholder="Email Id"
          size="large"
          className="rounded mb-4 mt-2"
          onChange={(e: any) => setEmail(e.target.value)}
        />
      </Card>
    </Page>
  );
}
