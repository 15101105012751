import React from "react";
import { Typography, Divider } from "antd";
import { FaHandPointRight } from "react-icons/fa";

const HowToWearSection = ({ title, steps }) => {
  return (
    <div className="mt-6 p-4 bg-white shadow-sm rounded-md">
      <Typography.Title
        level={4}
        className="text-center text-gray-800"
        style={{ fontWeight: "600", fontSize: "24px", marginBottom: "16px" }}
      >
        {title}
      </Typography.Title>
      <Divider className="mb-4" />
      <div className="space-y-4">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex items-start space-x-3 p-3 bg-gray-50 rounded-md shadow-sm hover:shadow-md transition-shadow"
            style={{ borderLeft: "4px solid #f56a00" }}
          >
            <FaHandPointRight
              className="text-xl text-gray-700"
              style={{ flexShrink: 0 }}
            />
            <div>
              <Typography.Text
                className="block text-lg font-semibold text-gray-800"
                style={{ marginBottom: "8px" }}
              >
                {step.title}
              </Typography.Text>
              <Typography.Text
                className="block text-gray-600"
                style={{ lineHeight: 1.5 }}
              >
                {step.description}
              </Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToWearSection;
