import React, { ReactNode } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface LabelProps {
    children: ReactNode; // Use ReactNode to accept any valid JSX children
}

const Label: React.FC<LabelProps> = ({ children }) => <Text >{children}</Text>;

export default Label;
