import React from "react";
import {
  WhatsAppOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import Whatsapp from "../assets/images/whatsapp.png";
import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/instagram.png";

const SocialMediaBar = () => {
  return (
    <div>
      <Typography.Text type="secondary" className="mb-2 block">
        Help & Support
      </Typography.Text>
      <div className="rounded-lg bg-orange-50 p-2 flex items-center">
        <div>
          <img src={Whatsapp} height={"35px"} width={"35px"} />
        </div>
        <div
          className="ml-2"
          onClick={() => {
            window.open("https://wa.me/7737998057", "_blank");
          }}
        >
          <Typography.Text className="block text-base font-semibold" >Contact Us</Typography.Text>
          {/* <Typography.Text  className="text-xs">
            You can call us from 10 AM - 8 PM
          </Typography.Text> */}
        </div>
      </div>
      
      <div className=" p-2 flex justify-evenly absolute bottom-2 right-2 left-2 rounded-sm" >
        <a
          href="https://wa.me/7737998057"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} height={"38"} width={"38"} />

        </a>
        <a
          href="https://www.facebook.com/devpunyatemple"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} height={"40px"} width={"40px"} />
        </a>
        <a
          href="https://www.instagram.com/devpunyaofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} height={"40px"} width={"40px"} />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaBar;
