import React, { Fragment, useState } from "react";
import Page from "../../layouts/Page";
import { Button, Card, Input, Typography, message } from "antd";
import Label from "../../components/Label";
import { postRequest } from "../../utils/api.utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { counselorStartInterKartEvent, createUserForInterKartEvent } from "../../events/interkartEvents";

export default function CounselorOnboard() {
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [problem_description, setproblem_description] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const banner =
    homeData &&
    homeData?.banner?.filter((tem: any) => tem?.page === "astro_start");

  const selectTagHadler = (tag: any) => {
    const isSelected = selectedTags.includes(tag);
    if (isSelected) {
      const flt = selectedTags.filter((tagItem: any) => tagItem !== tag);
      setSelectedTags(flt);
      return;
    }
    setSelectedTags([...selectedTags, tag]);
  };

  const creatOnBoaridng = async () => {
    if (!phone) {
      message.error("Please provide number");
      return;
    }

    setLoading(true);

    const res: any = null;
    // try {
    //   await axios("https://geolocation-db.com/json/");
    // } catch (error) {}

    const reqBody = {
      phone,
      problem_category: selectedTags,
      problem_description: problem_description,
      postal_code: res?.data?.postal,
    };

    postRequest(`/noauth-api/v1/login/user/getotp`, {
      phone: phone,
    })
      .then((data: any) => {
        postRequest("/noauth-api/v1/login/user/verifyotp", {
          otp: "2262",
          phone: phone,
          session_id: data?.data?.results?.session_id,
        }).then(async (res) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res?.data?.results })
          );

       
          await postRequest(`/noauth-api/v1/astro/createBooking`, reqBody)
            .then((_res: any) => {
              navigate("/counselor-info", { state: _res.data.results });
              setLoading(false);
              createUserForInterKartEvent({
                id: res?.data?.results?.userDetails?.id,
                name: res?.data?.results?.userDetails?.name,
                phone: phone,
                callback: () =>     
                counselorStartInterKartEvent({
                  phone,
                  problem_description: problem_description,
                  postal_code: res?.data?.postal,
                }),
              });

            })
            .catch((err: any) => {
              setLoading(false);
            });
        });
      })
      .catch((err: unknown) => {});
  };

  return (
    <Page
      footer={
        <div
          className={`flex items-center justify-around p-2 bg-white shadow-s w-full h-[6vh]`}
        >
          <Button
            loading={loading}
            onClick={() => creatOnBoaridng()}
            size="large"
            className="bg-orange-500 w-full"
            type="primary"
          >
            <Typography.Text className="font-semibold text-white text-xl">
              Submit
            </Typography.Text>
          </Button>
        </div>
      }
      className="px-2 mt-1"
    >
      <Card className="w-full h-full">
        <div>
          {banner &&
            banner?.map((bne: any) => {
              return (
                <div
                  key={bne.id}
                  onClick={() => {
                    if (bne?.deep_link) {
                      window.location.href = bne?.deep_link;
                    }
                  }}
                >
                  <img src={bne?.url} className="rounded shadow mb-4" />
                </div>
              );
            })}
        </div>

        <Label>What is your phone number?</Label>
        <Input
          placeholder="Phone number"
          size="large"
          className="rounded mb-4 mt-2"
          onChange={(e: any) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setPhone(e.target.value);
          }}
        />

        <Label>What is your category of problems?</Label>
        <div className="flex items-center flex-wrap mb-4 mt-2">
          {[
            "Marriage Related",
            "Health Related",
            "Love Related",
            "Business Related",
            "Peace of Mind",
            "Debt Related",
            "Pregnancy Related",
            "Others",
          ].map((cat: any) => {
            const isSelected = selectedTags.includes(cat);
            return (
              <Fragment>
                <div
                  onClick={() => {
                    selectTagHadler(cat);
                  }}
                  style={{
                    border: ".8px solid orange",
                  }}
                  className={`${
                    isSelected ? "bg-orange-400" : "text-transparent"
                  } rounded-full px-4 py-1  mr-2 mb-2 cursor-pointer`}
                >
                  <Typography.Text
                    className={`text-xs font-light ${
                      !isSelected ? "text-orange-500" : "text-white"
                    }`}
                  >
                    {cat}
                  </Typography.Text>
                </div>
              </Fragment>
            );
          })}
        </div>

        <Label>
          Describe Your questions in brief? {problem_description.length}
        </Label>
        <Input.TextArea
          rows={4}
          maxLength={250}
          placeholder="Describe your problem"
          size="large"
          onChange={(e) => setproblem_description(e.target.value)}
          className="rounded mb-4 mt-2"
        />
      </Card>
    </Page>
  );
}
