import HomeData from "../../data/HomeData";
import { SET_ADDRESSES, SET_CART_COUNT, SET_COLORS, SET_PRODUCTS } from "../types";

const initialProductsState: any = {
  homeData:HomeData
};

const homeReducer = (state = initialProductsState, action: any) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, homeData: action.payload };
    case SET_COLORS:
      return { ...state, colorsMap: action.payload };
    case SET_ADDRESSES:
      return { ...state, addressesData: action.payload };
    case SET_CART_COUNT:
      return { ...state, cartCount: action.payload };
    default:
      return state;
  }
};

export default homeReducer;
