import { Routes, Route } from "react-router-dom";
import RouteType from "./Route.type";
import { defaultRoutes } from "./allRoutes";
import { HelmetProvider } from "react-helmet-async";
import { Suspense, useEffect } from "react";
import Loader from "../components/Loader";
import { getRequest } from "../utils/api.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartCount,
  setColorsMap,
  setProducts,
} from "../redux/actions/homeAction";

export default function Index() {
  const dispatch = useDispatch();
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const session_id: string | null = localStorage.getItem("session_id");
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  useEffect(() => {
    getRequest<any>("/noauth-api/v1/home")
      .then((data) => {
        dispatch(setProducts(data?.results));
      })
      .catch((err: unknown) => {
        console.error("Error:", err);
      });
  }, [dispatch]);

  // useEffect(() => {
  //  setTimeout(() => {
  //   getRequest<any>("/noauth-api/v1/product/color")
  //   .then((data) => {
  //     dispatch(setColorsMap(data?.results));
  //   })
  //   .catch((err: unknown) => {
  //     console.error("Error:", err);
  //   });
  //  }, 2);
  // }, [dispatch]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     getRequest<any>(
  //       `/${
  //         user?.token ? "api" : "noauth-api"
  //       }/v1/cart/cartProductCount?session_id=${
  //         session_id ? JSON.parse(session_id) : ""
  //       }`
  //     )
  //       .then((data) => {
  //         dispatch(setCartCount(data?.results));
  //       })
  //       .catch((err: unknown) => {
  //         console.error("Error:", err);
  //       });
  //   }, 1);
  // }, [dispatch]);

  if (!homeData) return <Loader />;
  return (
    <HelmetProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          {defaultRoutes.map((route: RouteType, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
        </Routes>
      </Suspense>
    </HelmetProvider>
  );
}
