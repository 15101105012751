import axios from "axios";
import bcrypt from "bcryptjs";
import { sha256, sha224 } from "js-sha256";

declare global {
  interface Window {
    fbq: any;
  }
}

export const addToCartPX = (
  cartId: string,
  value?: number,
  content_type?: string,
  content_name?: string,
  content_ids?: string[],
  num_adults?: string
) => {
  const eventData = {
    content_type,
    content_ids,
    content_name,
    event_id: cartId || "",
    value: value || 0,
    currency: "INR",
    num_adults
  };

  // window.fbq("track", "AddToCart", eventData);
  sendFbEvent("AddToCart", eventData);
};

export const purchasePX = (
  orderID?: string,
  value?: number,
  content_type?: string,
  content_name?: string,
  content_ids?: string[],
  num_adults?: string
) => {
  const eventData = {
    content_type,
    content_ids,
    content_name,
    value: value || 0,
    currency: "INR",
    num_adults: num_adults,
  };

  const eventDataMeta = {
    content_type,
    content_ids,
    content_name,
    value: value || 0,
    currency: "INR",
  };

  sendFbEvent("Purchase", eventData);
};

export const leadPX = (
  content_category: string,
  content_name?: string,
) => {
  const eventData = {
    content_category,
    content_name,
  };


  sendFbEvent("Lead", eventData);
};

export const initiateCheckoutPX = (
  content_category: string,
  value?: number,
  contents?: string,
  content_ids?: string[],
) => {
  const eventData = {
    content_category,
    content_ids,
    contents,
    value: value || 0,
    currency: "INR",
  };

  sendFbEvent("InitiateCheckout", eventData);
};

export const sendFbEvent = async (event_name: string, event_data: any) => {
  const session_id: any = localStorage.getItem("session_id");
  const user: any = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;

  function getFBPCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbp=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  function getFBCCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbc=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  const fbpValue = getFBPCookie();
  const fbcValue = getFBCCookie();

  const TOKEN =
    "EAAzTkDVxRN4BO3qcxnTaZAlvMsVmKuGKwr1RqfgDVy9SGSbIZBoLvdCCPzPkTaxZBMx87AOZBVqJYeZAekzZATpUFmoiHqvM1pzZAczHZCUwJ8WFoSs78FCx5Cbn8YvUElDeyT4E4pzHqF2oH2Y4PnqFbN6UHuzEx9nLlriNmDTlNbTDyVAG3AI0P3fK8B9ePKnTbAZDZD";
  const PIXEL_ID = "1919239465176771";
  const API_VERSION = "";
  var seconds = new Date().getTime() / 1000;

  let res: any = null;
  // try {
  //   res = await axios("https://geolocation-db.com/json/");
  // } catch (error) {}

  const reqBody = {
    event_name: event_name,
    action_source: "website",
    event_time: seconds?.toFixed(0),
    event_id: JSON.parse(session_id),
    event_source_url: window.location.href,
    user_data: {
      external_id: [sha256(JSON.parse(session_id))],
      fbp: fbpValue,
      fbc: fbcValue,
      ...(user?.userDetails?.email && {
        em: [sha256(user.userDetails?.email)],
      }),
      ...(user?.userDetails?.phone && {
        ph: [sha256(user.userDetails?.phone)],
      }),
      ...(user?.userDetails?.fullname && {
        fn: [sha256(user.userDetails?.fullname)],
      }),
      ...(user?.userDetails?.fullname && {
        ln: [sha256(user.userDetails?.fullname)],
      }),
      // ...(res?.data &&
      //   res?.data?.IPv4 && { client_ip_address: res?.data?.IPv4 }),
      ...(res?.data &&
        res?.data?.country_name && {
          country: sha256(res?.data?.country_name),
        }),
      ...(res?.data &&
        res?.data?.postal && {
          zp: sha256(res?.data?.postal),
        }),
      ...(res?.data &&
        res?.data?.city && {
          ct: sha256(res?.data?.city),
        }),
      ...(res?.data &&
        res?.data?.state && {
          st: sha256(res?.data?.state),
        }),
      ...(window.navigator.userAgent && {
        client_user_agent: window.navigator.userAgent,
      }),
    },
    ...(event_data?.num_adults && {
      custom_data: {
        num_adults: event_data?.num_adults,
      },
    }),
    ...(event_data.currency && { currency: event_data.currency }),
    ...(event_data.value && { value: event_data.value }),
    ...(event_data.content_category && { content_category: event_data.content_category }),
    ...(event_data.content_name && { content_name: event_data.content_name }),
  };

  

  const reqBodyFBQ = {
    event: event_name,
    eventID: JSON.parse(session_id),
    external_id: [sha256(JSON.parse(session_id))],
    currency: "INR",
    value: event_data.value,
    content_type: event_data.content_type || "",
    content_ids: event_data?.content_ids || [],
    content_name: event_data?.content_name || "",
    content_category: event_data?.content_category || "Puja",
  };

  window.fbq("track", event_name, reqBodyFBQ);

  await axios
    .post(
      `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`,
      {
        data: [reqBody],
      }
    )
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
};
