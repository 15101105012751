import React, { useEffect, useState, useRef } from "react";
import { Input, Layout, Button, Divider, Spin } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";

const { Header, Footer } = Layout;

let endpoint = "https://aiguru-3gtio2vspq-el.a.run.app";

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const chatBoxRef = useRef(null);

  const startChat = async () => {
    const res = await axios.get(`${endpoint}/get_chats?user_id=${id}`);
    setMessages(res.data?.reverse());

    if (res?.data?.length === 0) {
      const res = await axios.post(`${endpoint}/start`, {
        user_id: id,
      });
    }

    setFetching(false);
  };

  const sendMessage = async () => {
    if (!inputMessage.trim()) return;

    setLoading(true);
    const newMessage = {
      question: inputMessage,
      answer: "Typing...",
    };

    setMessages([...messages, newMessage]);

    try {
      const res = await axios.post(`${endpoint}/ask`, {
        user_id: id,
        message: inputMessage,
      });

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === newMessage.id ? { ...msg, answer: res.data.response } : msg
        )
      );
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
      setInputMessage("");
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    startChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Layout className="fixed inset-0 bg-white border-gray-200">
      <Header className="bg-white shadow-md flex justify-between items-center p-4">
        <div className="flex items-center">
          <img
            src="https://cdn.pixabay.com/photo/2021/03/26/15/33/sadhu-6126225_640.jpg"
            alt="Logo"
            className="h-8 w-8 mr-2 rounded-full"
          />
          <h1 className="text-xl font-semibold">Pandit ji</h1>
        </div>
      </Header>
      {fetching ? (
        <div className="w-full h-[70vh] flex items-center justify-center">
          <Spin/>
        </div>
      ) : (
        <div
          ref={chatBoxRef}
          className="chat-box p-4 max-h-screen overflow-auto pt-16"
        >
          {messages.map((message) => (
            <div key={message.id} className="mb-4">
              <div className="flex justify-end">
                <div className="bg-orange-500 rounded-lg px-4 py-2 text-white text-right max-w-md">
                  <p>{message.question}</p>
                </div>
              </div>
              <div className="flex justify-start mt-2">
                <div className="bg-gray-200 rounded-lg px-4 py-2 text-left max-w-md">
                  <p>{message.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Divider />
      <Footer className="fixed bottom-0 left-0 right-0 bg-white p-4 border-t border-gray-200 flex">
        <Input
          size="large"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          disabled={loading}
        />
        <Button
          size="large"
          onClick={sendMessage}
          loading={loading}
          disabled={loading}
          className="ml-2"
        >
          Send
        </Button>
      </Footer>
    </Layout>
  );
};

export default ChatBox;
