import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Drawer, Image, Input, Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { getRequest } from "../utils/api.utils";
import { CloseOutlined } from "@ant-design/icons";
// import LogoName from "../assets/images/name_log.png";
import LogoIMAGE from "../assets/images/logo_image.jpeg";
import { MessageOutlined } from "@ant-design/icons";

import SocialMediaBar from "../components/SocialMediaBar";
const { Text } = Typography;

interface PageHeaderProps {
  title?: string;
  subTitle?: string;
  goBack?: boolean;
  back?: boolean;
  noBack?: boolean;
}

export default function Header({ title, noBack, back }: PageHeaderProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  const { cartCount } = useSelector((state: any) => state?.homeReducer);
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const [searchBox, setSearchBox] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<any>("");

  const [searchResults, setSearchResults] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (searchQuery && isMounted) {
      setIsLoading(true);

      getRequest<any>(`/noauth-api/v1/search?params=${searchQuery}`)
        .then((data) => {
          setIsLoading(false);
          setSearchResults(data?.results);
        })
        .catch((err: unknown) => {
          console.error("Error fetching products:", err);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [searchQuery]);

  const toggleSearchBox = () => {
    setSearchBox(!searchBox);
  };

  const [showPatti, setShowPatti] = useState<boolean>(true);
  const topPattiClose = sessionStorage.getItem("topPattiClose");

  return (
    <div
      className={`bg-white ${
        homeData &&
        homeData?.promoted_message &&
        topPattiClose !== "yes" &&
        showPatti
          ? "h-[10vh]"
          : "h-[7vh]"
      } `}
    >
      {/* Mobile menu */}
      {searchBox ? (
        <>
          <Drawer
            title={
              <div className="flex items-center">
                <Input
                  placeholder="Type here"
                  autoFocus
                  className="w-full h-[40px]"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CloseOutlined
                  onClick={() => {
                    setSearchResults([]);
                    setSearchBox(false);
                  }}
                  className="ml-2 mr-2"
                />
              </div>
            }
            placement="top"
            closable={false}
            onClose={() => {
              setSearchResults([]);
              setSearchBox(false);
            }}
            open={searchBox}
          >
            <div className="flex items-center justify-center w-full">
              {isLoading && <Spin />}

              {searchResults?.product?.length === 0 && !isLoading && (
                <Typography.Text className="block">
                  No Result Found
                </Typography.Text>
              )}
            </div>

            {searchResults &&
              searchResults?.product?.map((product: any) => {
                return (
                  <div
                    onClick={() =>
                      navigate(`/products/${product?.id}/${product?.name}`)
                    }
                    className="m-3 flex "
                  >
                    <Image
                      src={product?.images[0]}
                      style={{
                        width: 30,
                        height: 30,
                        objectFit: "cover",
                        marginRight: 10,
                      }}
                    />
                    <Typography.Text>{product?.name}</Typography.Text>
                  </div>
                );
              })}
          </Drawer>
        </>
      ) : (
        <>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40 " onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                    <div className="flex px-4 pb-2 pt-5">
                      <button
                        type="button"
                        className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="p-6">
                      <div
                        onClick={() => navigate("/")}
                        className="w-full mb-2"
                      >
                        <Typography.Text className="text-lg">
                          Home
                        </Typography.Text>
                      </div>

                      <div
                        onClick={() => navigate("/online-puja")}
                        className="w-full mb-2"
                      >
                        <Typography.Text className="text-lg">
                          Puja Services
                        </Typography.Text>
                      </div>

                      {/* <div
                        onClick={() => navigate("/real-temple-model")}
                        className="w-full mb-2"
                      >
                        <Typography.Text>Real Temple Model</Typography.Text>
                      </div> */}
                      {/* {user?.token && ( */}
                        <div
                          className="mb-2"
                          onClick={() => {
                            navigate(`/orders/1/puja`);
                          }}
                        >
                          <Typography.Text className="text-lg">
                            My Orders
                          </Typography.Text>
                        </div>
                      {/* )} */}

                      {/* <div
                        onClick={() => navigate("/home")}
                        className="w-full mb-2"
                      >
                        <Typography.Text>Customer Stories</Typography.Text>
                      </div> */}

                      <div
                        onClick={() => navigate("/faqs-and-testimonials")}
                        className="w-full mb-2"
                      >
                        <Typography.Text className="text-lg">
                          FAQs & Devotee Testimonials
                        </Typography.Text>
                      </div>

                      <Divider />

                      <div>
                        <SocialMediaBar />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <header className="relative h-full overflow-x-auto">
            {homeData &&
              homeData?.promoted_message &&
              topPattiClose !== "yes" &&
              showPatti && (
                <div className="w-full flex h-8 items-center justify-between bg-primary">
                  <p
                    className="font-light text-center w-full text-xs text-white"
                    // style={{
                    //   fontSize: 10,
                    // }}
                  >
                    {homeData?.promoted_message?.description}{" "}
                  </p>

                  <div className=" border rounded-full w-6 h-6 flex items-center justify-center mr-4">
                    <span
                      onClick={() => {
                        sessionStorage.setItem("topPattiClose", "yes");
                        setShowPatti(false);
                      }}
                      className="text-white"
                    >
                      X
                    </span>
                  </div>
                </div>
              )}
            <nav
              aria-label="Top"
              className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
            >
              <div className="flex items-center justify-between border-b border-gray-200">
                <div className="flex h-12 items-center">
                  {!noBack && (
                    <button
                      type="button"
                      className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
                      onClick={() => (back ? navigate(-1) : setOpen(true))}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open menu</span>
                      {back ? (
                        <ArrowLongLeftIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </button>
                  )}
                  {/* Logo */}
                  <div className="ml-2 flex lg:ml-0">
                    <h1>
                      {title ? (
                        <Text ellipsis>
                          {title?.length > 24
                            ? title?.slice(0, 24) + "..."
                            : title}
                        </Text>
                      ) : (
                        <Typography.Text
                          onClick={() => navigate("/home")}
                          className="flex items-center text-xl text-primary"
                        >
                          <img
                            className="h-[100%] w-[180px] object-contain ml-[-15px]"
                            src={LogoIMAGE}
                            alt="dev punya"
                          />
                        </Typography.Text>
                      )}
                    </h1>
                  </div>

                  {/* <div className="ml-auto flex items-center">
                    <div className="flex lg:ml-6" onClick={toggleSearchBox}>
                      <a
                        href="#"
                        className="p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Search</span>
                        <MagnifyingGlassIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div> */}
                </div>

                <Button
                  onClick={() =>
                    //  window.location.href="https://play.google.com/store/apps/details?id=com.devpunyaa"

                    navigate("/astro-guru/find")
                  }
                  size="small"
                  className="bg-orange-100 font-bold"
                >
                  Ask AstroGuru
                </Button>
              </div>
            </nav>
          </header>
        </>
      )}
    </div>
  );
}
