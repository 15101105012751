import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { WhatsAppOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { IoLocateOutline, IoCalendar } from "react-icons/io5";
import Sampark from "../../../components/Sampark";

const TrendingPujaCard = ({ item }: any) => {
  const [desc, setDesc] = useState<string>(
    item.description?.slice(0, 40) + "..."
  );
  const [more, setMore] = useState<boolean>(false);

  useEffect(() => {
    setDesc(more ? item.description : item.description?.slice(0, 40) + "...");
  }, [more]);

  const navigate = useNavigate();

  
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/pooja/${item.id}`);
      }}
      style={{
        // border:"1px solid gray"
      }}
      className="max-w-sm bg-white rounded-lg mx-auto  shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out"
    >
      <div className="relative">
        {/* Trending Puja Tag */}
        {/* {item.decoration_tag && (
          <span className="absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-3 py-1 rounded-tr-lg rounded-br-lg">
            {item.decoration_tag?.toUpperCase()}
          </span>
        )} */}
        {/* Date Tag */}
        {item?.decoration_tag && (
          //  <span className="absolute top-0 left-0 bg-primary text-white text-xs font-semibold px-3 py-1 rounded-br-lg">
          //     {item.decoration_tag}
          //   </span>
          <Typography.Text className="absolute px-2 text-lg z-10 bg-orange-500 text-white left-0">
            {item?.decoration_tag}
          </Typography.Text>
        )}
        {/* Puja Image */}
        <img
          className="object-cover w-full border bg-gray-300"
          src={item?.default_image ? item?.default_image : item?.images[0]}
          alt="Puja"
          style={{ height: "130px" }} 
        />
      </div>
      <div className="px-5 py-2">
        <h3 className="text-sm font-bold text-gray-800 ">{item.name}</h3>
        <h6 className="text-xs  text-orange-500 ">{item.benefits}</h6>

        <h3 className="text-xs font-light text-gray-400 mb-2">
          {desc}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setMore(!more);
            }}
            className="text-blue-500"
          >
            read {more ? "less" : "more"}
          </span>
        </h3>
        {/* <p className="mt-2 text-gray-600 text-sm">
          दिवाली पंचांग विशेष राम जनकी पूजन...
        </p> */}
        {/* Puja Location */}
        <div className="flex items-center  text-gray-700 mb-1">
          <IoLocateOutline />
          <span className="ml-2 text-xs">
            {item.mandir_name}, {item.location}
          </span>
        </div>

      <div className="flex items-center justify-between w-full">
      <div className="flex items-center  text-gray-700">
          <IoCalendar />
          <span className="ml-2" style={{
            fontSize:10
          }}>
            <span className="bg-red-100 text-red-500 font-semibold px-1 py-1 rounded-tl-lg rounded-bl-lg">
              {moment(item.startingAt).format("DD MMMM YYYY")}
            </span>
          </span>
        </div>
        <Button
          type="primary"
          className="bg-green-500 "
          style={{
            border: "1px solid black",
          }}
          icon={<ArrowRightOutlined />}
          onClick={() => {
            navigate(`/pooja/${item.id}`);
          }}
          size="middle"
        >
          Participate in Puja
        </Button>
      </div>
      </div>
      {/* Action Buttons */}
   
    </div>
  );
};

export default TrendingPujaCard;
