import React, { useEffect, useRef, useState } from "react";
import { List, Input, Button, Typography } from "antd";
import { getRequest, postRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import { CloseOutlined } from "@ant-design/icons";
import Priest from "../../assets/priest.png";

const ChatComponent = ({ styles = "", pooja_id = "" }) => {
  const [chatData, setChatData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const flatListRef = useRef(null);
  const session_id = JSON.parse(localStorage.getItem("session_id") || "");

  const getChats = async () => {
    const res = await getRequest(
      `/noauth-api/v1/chat/web/messages?session_id=${session_id}`
    );

    setChatData(res?.results || []);
    setLoading(false);
  };

  useEffect(() => {
    if (isChatOpen) {
      setLoading(true);
      getChats();
    }
  }, [isChatOpen]);

  useEffect(() => {
    if (chatData.length > 0 && flatListRef.current) {
      flatListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatData]);

  const transformChatHistory = (history) => {
    return history.flatMap((item) => {
      const transformed = [];
      if (item.userMessage) {
        transformed.push({
          role: "user",
          parts: [{ text: item.userMessage }],
          createdAt: item.createdAt,
        });
      }
      if (item.modelMessage) {
        transformed.push({
          role: "model",
          parts: [{ text: item.modelMessage }],
          createdAt: item.createdAt,
        });
      }
      return transformed;
    });
  };

  const chatHandler = async () => {
    if (!message.trim()) return;

    const newMessage = {
      userMessage: message.trim(),
      createdAt: new Date().toISOString(),
    };

    const updatedChatData = [...chatData, newMessage];
    setChatData(updatedChatData);
    setMessage("");
    setTyping(true); // Show typing indicator

    try {
      const transformedHistory = transformChatHistory(updatedChatData);

      const reqBody = {
        userMessage: message.trim(),
        chatHistory: transformedHistory,
        messageCounter: updatedChatData.length,
      };

      // Send the request to the server
      await postRequest(
        `/noauth-api/v1/chat/web?session_id=${session_id}&pooja_id=${pooja_id}`,
        reqBody
      );

      // Optionally reload the chat history from the server after sending the message
      getChats();
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setTyping(false); // Hide typing indicator after response
    }
  };

  return (
    <div>
      {/* Blur Background Overlay */}
      {isChatOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
          onClick={() => setIsChatOpen(false)}
        ></div>
      )}

      {/* Floating Button */}
      {!isChatOpen && (
        <button
          className={`fixed flex flex-col shadow-xl items-center justify-center w-[80px] h-[80px] p-2 border border-orange-500 text-xl rounded-full bg-white text-white z-50 bottom-5 right-5 ${styles}`}
          shape="round"
          size="large"
          onClick={() => setIsChatOpen(true)}
        >
          <img src={Priest} className="w-[40px] h-[40px]" />
          <Typography.Text className="text-orange-500 text-lg">
            Help
          </Typography.Text>
        </button>
      )}

      {/* Floating Chat Window */}
      {isChatOpen && (
        <div className="fixed bottom-0 right-0 w-full md:w-1/3 h-[70%] bg-white shadow-lg z-50 flex flex-col">
          <div className="p-4 bg-gray-800 text-white flex justify-between items-center">
            <h3 className="text-lg">Ask Your Questions!</h3>
            <CloseOutlined
              className="text-xl cursor-pointer"
              onClick={() => setIsChatOpen(false)}
            />
          </div>

          <div className="flex-1 overflow-auto p-4">
            {loading && <Loader />}
            <List
              dataSource={chatData}
              renderItem={(item) => (
                <>
                  {item.userMessage && (
                    <div className="flex justify-end mb-4">
                      <div className="bg-orange-500 text-white p-3 rounded-lg shadow-md max-w-xs">
                        {item.userMessage}
                      </div>
                    </div>
                  )}
                  {item.modelMessage && (
                    <div className="flex justify-start mb-4">
                      <div className="bg-gray-300 text-black p-3 rounded-lg shadow-md max-w-xs">
                        {item.modelMessage}
                      </div>
                    </div>
                  )}
                </>
              )}
            />
            {typing && (
              <div className="flex justify-start mb-4">
                <div className="bg-gray-300 text-black p-3 rounded-lg shadow-md max-w-xs">
                  Pandit DevPunya Shastri is typing...
                </div>
              </div>
            )}
            <div ref={flatListRef}></div>
          </div>

          <div className="p-4 shadow-lg flex items-center space-x-2 bg-gray-100">
            <Input
              className="flex-1"
              placeholder="Type your message..."
              value={message}
              size="large"
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={chatHandler}
            />
            <Button
              size="large"
              className="bg-orange-500 border-none text-white"
              onClick={chatHandler}
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
