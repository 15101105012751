import { useState, useEffect } from "react";
import { User } from "../types";
import { AxiosError } from "axios";

export function useUser() {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error | unknown>(null);

  if (error) {
    throw error;
  }

  useEffect(() => {
    setTimeout(() => {
      setUser({
        id: "98",
        name: "Shravan Meena",
        org: "Symita",
        roles: ["user"],
      });
    }, 1000);
    // get<User>('/api/v1/user')
    //     .then((user) => setUser({ ...user, org: 'org1' }))
    //     .catch((err: unknown) => {
    //         if (err instanceof AxiosError && err.response?.status === 403 && err.response?.data?.location) {
    //             window.location = err.response.data.location;
    //         } else {
    //             setError(err);
    //         }
    //     });
  }, []);

  return user;
}
