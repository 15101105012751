import React from "react";
import { Typography } from "antd";

const BenefitsSection = ({ benefits }) => {
  return (
    <>
    <div className="mt-4 px-1 bg-gradient-to-r from-orange-400 to-yellow-500 rounded-lg shadow-sm">
      <h3 className="text-2xl font-bold text-white text-center mb-2">Benefits</h3>
      <div className="space-y-1">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex items-start space-x-4 bg-white p-1 rounded-lg shadow-md">
            <div className="text-4xl text-orange-500">
              {benefit.link ? <img src={benefit.icon} alt="icon" className="w-10 h-10"/> : <span>🔸</span>}
            </div>
            <div>
              <Typography.Text  className="text-gray-800 text-lg font-bold">
                {benefit.title}
              </Typography.Text>
              <Typography.Text className="text-gray-600 text-sm block font-extralight">
                {benefit.description}
              </Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>

  );
};

export default BenefitsSection;



