export const antThemeProvider = {
  token: {
    colorPrimary: "#800000",
    colorSecondary: "#556B2F",
    // colorBorder: "#f4f4f4",
    borderRadius: 4,
  },
  components: {
    Segmented: {},
    Radio: {},
    Input: {},
    Button: {},
    Select: {},
    Tabs: {},
  },
};
