// store.ts
import { combineReducers, createStore } from 'redux';
import homeReducer from "./reducers/homeReducer"

const rootReducer = combineReducers({
    homeReducer,
  });
  

const store = createStore(rootReducer);

export default store;
