// import React, { useEffect, useState } from "react";
// import { Breadcrumb, Button, Collapse, Divider, Empty, Typography } from "antd";
// import { HomeOutlined } from "@ant-design/icons";
// import Page from "../../layouts/Page";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getRequest } from "../../utils/api.utils";
// import { capitalizeFirstLetter } from "../../utils/helper.utils";
// import Loader from "../../components/Loader";
// import PoojaCarousel from "../pooja/PoojaCarousel";

// const GemstoneDetail = () => {
//   const { state } = useLocation();
//   const navigate = useNavigate();
//   const [gemstoneDetail, setGemstoneDetail] = useState(state);
//   const [loading, setLoading] = useState(true);
//   const { id } = useParams();

//   const getGemstones = async () => {
//     const res = await getRequest(
//       `/noauth-api/v1/gemstone/getProductListing?product_id=${id}`
//     );
//     setGemstoneDetail(res?.results);
//     setLoading(false);
//   };

//   useEffect(() => {
//     getGemstones();
//   }, [id]);

//   if (loading)
//     return (
//       <Page>
//         <Loader />
//       </Page>
//     );
//   if (!gemstoneDetail)
//     return (
//       <Page>
//         <Empty />
//       </Page>
//     );

//   const benefitsItems = gemstoneDetail.tabs?.data.find((tab) => tab.title === "Benefits")?.data || [];
//   const benefitsData = benefitsItems.map((item, index) => ({
//     key: index + 1,
//     label: (
//       <Typography.Text className="font-semibold text-xs">
//         {item.title}
//       </Typography.Text>
//     ),
//     children: (
//       <Typography.Text className="text-xs">{item.description}</Typography.Text>
//     ),
//   }));

//   const faqItems = gemstoneDetail.tabs?.data.find((tab) => tab.title === "FAQs")?.data || [];
//   const faqData = faqItems.map((item, index) => ({
//     key: index + 1,
//     label: (
//       <Typography.Text className="font-semibold text-xs">
//         {item.title}
//       </Typography.Text>
//     ),
//     children: (
//       <Typography.Text className="text-xs">{item.description}</Typography.Text>
//     ),
//   }));

//   const testimonials = gemstoneDetail.tabs?.data.find((tab) => tab.title === "Reviews")?.data || [];
//   const testimonialItems = testimonials.map((testimonial, index) => (
//     <div
//       key={index}
//       className="p-4 border rounded-lg shadow-md min-w-[300px] mr-4"
//     >
//       <Typography.Text className="block font-semibold text-lg">
//         {testimonial.name}
//       </Typography.Text>
//       <Typography.Text className="block mt-2 text-gray-600">
//         {testimonial.description}
//       </Typography.Text>
//     </div>
//   ));

//   return (
//     <Page>
//       <div className="max-w-4xl mx-auto px-2 bg-white min-h-screen">

//         {/* Gemstone Details */}

//         <PoojaCarousel  product={gemstoneDetail} />
//         <div className="mt-4 bg-white p-4 rounded-lg border">
//           <h1 className="text-xl font-bold text-black">
//             {capitalizeFirstLetter(gemstoneDetail.name)}
//           </h1>

//           <div className="flex mt-4">

//             <div>
//               <p className="text-gray-700 text-sm">{gemstoneDetail.category}</p>
//               <p className="text-gray-700 text-sm">
//                 {gemstoneDetail.description}
//               </p>
//               <div className="mt-4">
//                 <span className="text-lg font-bold text-green-500">
//                   ₹ {gemstoneDetail.discounted_price}
//                 </span>
//                 <span className="text-gray-500 line-through ml-2">
//                   ₹{gemstoneDetail.price}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* FAQ Section */}
//         <div className="mt-8">
//           <h3 className="text-xl font-medium">FAQ</h3>
//           <div className="my-4">
//             <Collapse defaultActiveKey={[1]} items={faqData} />
//           </div>
//         </div>

//          {/* Benefits Section */}
//          <div>
//           <h3 className="text-xl font-medium">Benefits</h3>
//           <div className="my-4">
//             <Collapse defaultActiveKey={[1]} items={benefitsData} />
//           </div>
//         </div>

//         {/* Testimonials Section */}
//         <div>
//           <h3 className="text-xl font-medium">Testimonials</h3>
//           <div className="flex overflow-x-auto space-x-1 p-2 my-2">
//             {testimonialItems}
//           </div>
//         </div>

//         <Divider />
//         <div
//           className={`mt-6 p-1 w-full ${"bg-green-500"} absolute bottom-0 left-0 right-0`}
//         >
//           <Button
//             size="large"
//             className="w-full border-none text-white font-medium "
//             onClick={() => navigate(`/gemstones/${gemstoneDetail.id}/order`)}
//           >
//             Book Now
//           </Button>
//         </div>
//       </div>
//     </Page>
//   );
// };

// export default GemstoneDetail;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Breadcrumb, Divider, Collapse, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import PoojaCarousel from "../pooja/PoojaCarousel";
import WhyChooseDevPunya from "../../components/WhyChooseDevPunya";
import CustomerReviews from "../../components/CustomerReviews";
import BenefitsSection from "../../components/BenefitsSection";
import AdditionalKavachItems from "../../components/AdditionalKavachItems";
import Footer from "../../layouts/Footer";
import DimensionsSection from "../../components/DimensionsSection";
import HowToWearSection from "../../components/HowToWearSection";

const GemstoneDetail = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // State to control description toggle

  const getGemstones = async () => {
    const res = await getRequest(
      `/noauth-api/v1/gemstone/getProductListing?product_id=${id}`
    );
    setProduct(res?.results);
    setLoading(false);
  };

  useEffect(() => {
    getGemstones();
  }, [id]);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  if (loading)
    return (
      <Page>
        <Loader />
      </Page>
    );
  if (!product) {
    return (
      <Page>
        <div className="text-center py-20">
          <h2 className="text-2xl text-gray-700">No product found.</h2>
          <Button type="primary" onClick={() => navigate("/products")}>
            Go Back
          </Button>
        </div>
      </Page>
    );
  }

  const benefitsItems =
    product.tabs?.data.find((tab) => tab.title === "Benefits")?.data || [];

  const faqItems =
    product.tabs?.data.find((tab) => tab.key === "FAQs")?.data || [];
  const faqData = faqItems.map((item, index) => ({
    key: index + 1,
    label: (
      <Typography.Text className="font-semibold text-xs">
        {item.title}
      </Typography.Text>
    ),
    children: (
      <Typography.Text className="text-xs">{item.description}</Typography.Text>
    ),
  }));

  const testimonials =
    product.tabs?.data.find((tab) => tab.key === "reviews") || [];

  const whyChooseUs =
    product.tabs?.data.find((tab) => tab.key === "Why_Choose") || [];

  const Additional_Kavach_Items =
    product.tabs?.data.find((tab) => tab.key === "Additional_Kavach_Items") ||
    [];

  const how_to_wear =
    product.tabs?.data.find((tab) => tab.key === "how_to_wear") || [];

  const productDimensions = product.tabs?.data.find(
    (tab) => tab.key === "dimensions"
  );

  return (
    <Page>
      <div className="max-w-5xl mx-auto px-2 bg-white shadow-lg rounded-lg min-h-screen">
        <PoojaCarousel product={product} setBgBlur={() => {}} />

        <div className="mt-2">
          {/* Product Details */}
          <div className="p-2 w-full">
            <h1 className="text-2xl font-bold text-gray-900 ">
              {product.name}
            </h1>
            <p className="text-base text-gray-700 ">
              {isDescriptionExpanded
                ? product.description
                : `${product.description?.slice(0, 120)}...`}
              <span
                className="text-blue-500 text-xs"
                onClick={toggleDescription}
              >
                {isDescriptionExpanded ? "read Less" : "read More"}
              </span>
            </p>

            <div className="flex items-center mt-4">
              <p className="text-2xl text-green-600 font-bold">
                ₹{product.discounted_price}
              </p>
              <p className="text-xl text-gray-500 font-bold line-through ml-2">
                ₹{product.price}
              </p>
            </div>
          </div>
        </div>

        {productDimensions?.data && (
          <DimensionsSection
            dimensions={productDimensions.data}
            title={productDimensions?.title}
          />
        )}
        {Additional_Kavach_Items?.data?.length > 0 && (
          <AdditionalKavachItems
            items={Additional_Kavach_Items?.data}
            title={Additional_Kavach_Items?.title}
          />
        )}

        {benefitsItems?.length > 0 && (
          <BenefitsSection benefits={benefitsItems} />
        )}

        {how_to_wear.data?.length > 0 && (
          <HowToWearSection
            title={how_to_wear.title}
            steps={how_to_wear.data}
          />
        )}

        {/* Why Choose DevPunya Section */}
        {whyChooseUs.data?.length > 0 && (
          <WhyChooseDevPunya
            title={whyChooseUs.title}
            reasons={whyChooseUs.data}
          />
        )}

        {/* Testimonials Section */}
        {testimonials?.data?.length > 0 && (
          <CustomerReviews
            title={testimonials.title}
            reviews={testimonials?.data}
          />
        )}

        {/* FAQ Section */}
        <div className="mt-2">
          <h3 className="text-xl font-medium">FAQ</h3>
          <div className="my-4">
            <Collapse defaultActiveKey={[1]} items={faqData} />
          </div>
        </div>

        <Footer />
        <Divider />
        <div
          className={`mt-6 p-1 w-full ${"bg-green-500"} absolute bottom-0 left-0 right-0`}
        >
          <Button
            size="large"
            className="w-full border-none text-white font-medium "
            onClick={() => navigate(`/gemstones/${product.id}/order`)}
          >
            Book Now
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default GemstoneDetail;
