import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../utils/helper.utils";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";

const AbhimantritProductGrid = ({ products,type }) => {
  const navigate = useNavigate();

  // Sort products by rank, with lower rank values appearing first
  const sortedProducts = products.sort((a, b) => a.rank - b.rank);

  return (
    <div className="grid grid-cols-2 gap-1 max-w-4xl mx-auto">
      {sortedProducts.map((product) => (
        <div
          onClick={() =>
            navigate(`/${type || "abhimantrit-products"}/${product.id}`, {
              state: product,
            })
          }
          key={product.id}
          className="relative bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 ease-in-out"
        >
          {product.decoration && (
            <div className="absolute top-2 right-2 bg-orange-500 text-white rounded-full px-2 py-1 text-xs z-10">
              {capitalizeFirstLetter(product.decoration)}
            </div>
          )}
          <img
            alt={product.name}
            src={
              product.images?.length > 0
                ? product.images[0]
                : "https://devpunya.com/static/media/logo_image.dbe451c63a7253b30299.jpeg"
            }
            className="w-full h-[100px] object-cover"
          />
          <div className="p-2">
            <Title product={product} />

            <Desc product={product} />

            <div className="flex items-center justify-between">
              <div className="flex items-center ">
                <p className="text-lg text-green-500 font-bold">₹{product.discounted_price}</p>
              {product.discounted_price !== product.price &&   <p className="text-gray-400 line-through ml-2">
                  ₹{product.price}
                </p>}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AbhimantritProductGrid;

function Desc({ product }) {
  const [desc, setDesc] = useState( product?.description?.length > 50
    ? product?.description?.slice(0, 50) + "..."
    : product.description);
  return (
    <Typography.Text type="secondary" className="text-xs block">
      {desc}
    </Typography.Text>
  );
}

function Title({ product }) {
  const [title, setTitle] = useState(
    product?.name?.length > 35
      ? product?.name?.slice(0, 35) + "..."
      : product.name
  );
  return (
    <Typography.Text className="text-sm font-medium">{title}</Typography.Text>
  );
}
