import {
  Button,
  Card,
  Empty,
  Image,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import Page from "../../layouts/Page";
import { Fragment, useEffect, useState } from "react";
import { generateDateArrayWithMonthAndDayNames } from "../../utils/helper.utils";
import { getRequest, postRequest } from "../../utils/api.utils";
import { useLocation, useParams } from "react-router-dom";
import Label from "../../components/Label";
import { addToCartPX } from "../../events/facebookPexelsEvent";
import vd from "../../assets/open_loading.gif";
import { counselorDetailsInterKartEvent, counselorPayStartInterKartEvent } from "../../events/interkartEvents";

function removePastTimes(timesArray: any) {
  // Get the current time
  const currentTime = new Date();

  // Filter the array to remove past times
  const futureTimes = timesArray?.filter((time: any) => {
    // Convert the time string to a Date object
    const timeParts = time.match(/(\d+):(\d+) (\w+)/);

    if (!timeParts) {
      return timesArray;
    }

    const hours = parseInt(timeParts[1], 10) + (timeParts[3] === "pm" ? 12 : 0);
    const minutes = parseInt(timeParts[2], 10);

    // Create a new Date object for the time today
    const timeToday = new Date(currentTime);
    timeToday?.setHours(hours, minutes, 0, 0);

    // Compare the time with the current time
    return timeToday > currentTime;
  });
  return futureTimes;
}

function getDaySchedule(day: string, scheduleData: any) {
  // Return the array for the specified day, or an empty array if the day is not found
  const data = scheduleData[day] || [];
  const newData = removePastTimes(data);

  return newData?.map((item: any) => {
    return {
      time: item,
      day,
    };
  });
}

export default function CounselorDetails() {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedTime, setSelectedTime] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduling, setScheduling] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const { id } = useParams();
  const location = useLocation();

  const getGuruDetails = async () => {
    await postRequest(`/noauth-api/v1/astro/getPanditForBooking`, {
      booking_id: id,
    })
      .then((_res: any) => {
        setLoading(false);
        setData(_res.data?.results);
        counselorDetailsInterKartEvent({
          booking_id:id,
          name:_res.data?.results?.name
        })
        addToCartPX(
          "",
          _res.data?.results?.pricing,
          "astro",
          _res.data.results.name,
          [id || ""]
        );
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getGuruDetails();
  }, []);

  const scheduleData =
    data?.slots &&
    selectedData?.dayLong &&
    getDaySchedule(selectedData?.dayLong, data?.slots);

  const isExperties = true;

  const scheduleNow = async () => {
    const reqBody = {
      booking_id: id,
      slot: selectedTime?.time,
      booking_date: selectedDate,
    };
    setScheduling(true);
    counselorPayStartInterKartEvent(reqBody)

    await postRequest(`/noauth-api/v1/astro/bookSlot`, reqBody)
      .then(async (_res: any) => {
        await postRequest(`/noauth-api/v1/astro/astroPayment`, {
          booking_id: id,
        })
          .then((_payemntInitData: any) => {
            setTimeout(() => {
              setScheduling(false);
              window.location =
                _payemntInitData?.data?.results?.initData?.data?.instrumentResponse?.redirectInfo?.url;
            }, 2000);
          })
          .catch((err: any) => {
            setScheduling(false);
          });
      })
      .catch((err: any) => {});
  };

  const dateArr = generateDateArrayWithMonthAndDayNames();

  if (scheduling) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="bg-red-500">
          <img src={vd} alt="loading..." className="mt-[-200px]" />
        </div>
        <Typography.Text className="text-center block mt-[-150px] text-2xl m-10">
          Confirming your booking. Proceed to payment
        </Typography.Text>
      </div>
    );
  }

  return (
    <Page
      footer={
        <div
          className={`flex items-center justify-around p-2 bg-white shadow-s w-full h-[6vh]`}
        >
          <Button
            size="large"
            onClick={() =>
              selectedDate && selectedTime
                ? scheduleNow()
                : message.error("Please select slot")
            }
            className={`${
              selectedDate && selectedTime ? "bg-orange-500" : "bg-gray-500"
            } w-full`}
            type="primary"
          >
            <Typography.Text className="font-semibold text-white text-xl">
              Schedule Now
            </Typography.Text>
          </Button>
        </div>
      }
      className="px-2 mt-1"
    >
      {loading ? (
        <Spin />
      ) : (
        <div className="p-2">
          <div className="shadow rounded p-4  bg-white">
            {data?.image && (
              <div className="w-full shadow rounded flex items-center justify-center bg-gradient-to-r from-slate-200 to-slate-300">
                <Image width={160} height={160} src={data?.image} />
              </div>
            )}
            <Typography.Text className="text-lg font-semibold block mt-2">
              {data?.name}
            </Typography.Text>
            <Typography.Text type="secondary" className="text-xs block">
              {!location.state?.problem_category ||
                (location.state?.problem_category?.length == 0 && (
                  <Tag className="text-xs" color="orange">
                    Expert Astrologer
                  </Tag>
                ))}
              {location.state?.problem_category?.map((exp: any, i: any) => {
                return (
                  <Tag className="text-xs mt-2" color="orange">
                    <Fragment>
                      {exp}
                      {` `}
                    </Fragment>
                  </Tag>
                );
              })}
            </Typography.Text>
          </div>

          <div className="flex items-center justify-between mt-2">
            <div className="shadow rounded px-4 py-2 w-[49%] bg-white flex flex-col items-center justify-center ">
              <Typography.Text className="text-sm font-semibold">
                Experience
              </Typography.Text>
              <Typography.Text className="text-sm font-semibold text-orange-500 block">
                {data?.experience}
              </Typography.Text>
            </div>

            <div className="shadow rounded px-4 py-2 w-[49%] bg-white flex flex-col items-center justify-center">
              <Typography.Text className="text-sm font-semibold">
                Pricing
              </Typography.Text>
              <Typography.Text className="text-sm font-semibold text-orange-500 block">
                ₹{data?.pricing} per session
              </Typography.Text>
            </div>

            {/* <div className="shadow rounded px-4 py-2 w-[32%] bg-white flex flex-col items-center justify-center ">
              <Typography.Text className="text-sm font-semibold">
                Education
              </Typography.Text>
              <Typography.Text className="text-sm font-semibold text-orange-500 block">
                {data?.education ? "B.Tech" : "-"}
              </Typography.Text>
            </div> */}
          </div>

          <div className="shadow rounded px-4 py-2 mt-2 bg-white">
            <Typography.Text className="text-lg font-semibold">
              About The Guru
            </Typography.Text>
            <Typography.Text type="secondary" className="block">
              {data?.description?.slice(0, 50)}{" "}
              {data?.description?.length > 50 && (
                <Typography.Text className="text-orange-500">
                  Read More. . .
                </Typography.Text>
              )}
            </Typography.Text>
          </div>

          <div className="py-2 mt-2">
            <Typography.Text className="text-lg font-semibold block mb-1">
              Available Date
            </Typography.Text>

            <div className="flex overflow-scroll w-full flex-nowrap pb-4 ">
              {dateArr?.map((date: any) => {
                const isSelected = selectedDate == date?.date;
                return (
                  <div
                    onClick={() => {
                      setSelectedDate(date?.date);
                      setSelectedData(date);
                      setSelectedTime(null);
                    }}
                    className={`p-2 ${
                      isSelected ? "bg-orange-500" : "bg-white"
                    } mr-2 rounded-2xl flex flex-col items-center justify-center w-full shadow`}
                  >
                    <Typography.Text
                      className={`block  text-center ${
                        date?.flag ? "w-[90px]" : "w-[60px]"
                      } ${isSelected ? "text-white" : "text-black"} text-sm`}
                    >
                      {date?.flag
                        ? date?.flag
                        : `${date?.date?.slice(8, 10)}  ${date.dayShort}`}
                    </Typography.Text>
                  </div>
                );
              })}
            </div>

            <Typography.Text className="text-lg font-semibold mt-4 block mb-1">
              Available Time
            </Typography.Text>

            <div className="flex pb-4 items-center justify-between flex-wrap w-full">
              {!scheduleData && (
                <div className="flex items-center justify-center w-full">
                  <Empty
                    description={<Label>Please Select Available Date</Label>}
                  />
                </div>
              )}
              {scheduleData?.map((slt: any) => {
                const isSelected =
                  selectedTime?.day === slt?.day &&
                  selectedTime?.time === slt?.time;
                return (
                  <div
                    onClick={() => {
                      setSelectedTime(slt);
                    }}
                    className={`p-2 ${
                      isSelected ? "bg-orange-500" : "bg-white"
                    } rounded-2xl flex  items-center justify-center w-[32%] shadow mb-2`}
                  >
                    <Typography.Text
                      className={`block text-center ${
                        isSelected ? "text-white" : "text-black"
                      } text-sm font-light`}
                    >
                      {slt?.time}
                    </Typography.Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
