import React from "react";
import { Card, Typography } from "antd";

const WhyChooseDevPunya = ({ title, reasons }) => {
  return (
    <div className="mt-8">
      <h3 className="text-xl font-medium text-center mb-4">
        {title}
      </h3>
      <div className="flex flex-col space-y-1">
        {reasons.map((reason, index) => (
          <div key={index} className="rounded-lg shadow-md border px-4 py-2">
            <div className="flex flex-row items-center ">
              {reason.link ? (
                <img
                  src={reason.link}
                  alt={reason.title}
                  className="w-12 h-12 object-contain mr-4"
                />
              ) : (
                <div className="text-3xl mr-4">{reason.icon}</div>
              )}
              <div>
                <Typography.Text className="block font-semibold text-sm">
                  {reason.title}
                </Typography.Text>
              </div>
            </div>
            <Typography.Text className="block text-gray-600">
              {reason.description}
            </Typography.Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseDevPunya;
