import React from "react";
import { Card, Typography, Divider } from "antd";
import { FaWeight, FaRulerVertical, FaRulerHorizontal, FaCogs } from "react-icons/fa";

const DimensionsSection = ({ title, dimensions }) => {
  return (
    <div className="rounded-lg shadow-sm mb-4 border-2 border-orange-300 p-4 bg-gradient-to-br from-yellow-100 to-orange-100">
      <Typography.Title level={4} className="text-center text-orange-700 mb-2">
        {title}
      </Typography.Title>
      <Divider className="my-2 bg-orange-400" />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <FaWeight className="text-2xl text-orange-600 mr-3" />
          <Typography.Text className="font-semibold text-gray-800">
            Weight: <span className="text-orange-800">{dimensions.weight}</span>
          </Typography.Text>
        </div>
        <div className="flex items-center">
          <FaRulerVertical className="text-2xl text-orange-600 mr-3" />
          <Typography.Text className="font-semibold text-gray-800">
            Height: <span className="text-orange-800">{dimensions.height}</span>
          </Typography.Text>
        </div>
        <div className="flex items-center">
          <FaRulerHorizontal className="text-2xl text-orange-600 mr-3" />
          <Typography.Text className="font-semibold text-gray-800">
            Width: <span className="text-orange-800">{dimensions.width}</span>
          </Typography.Text>
        </div>
        <div className="flex items-center">
          <FaCogs className="text-2xl text-orange-600 mr-3" />
          <Typography.Text className="font-semibold text-gray-800">
            Material: <span className="text-orange-800">{dimensions.material}</span>
          </Typography.Text>
        </div>
      </div>
      <Divider className="my-2 bg-orange-400" />
      <Typography.Text className="block text-gray-800 text-sm">
        {dimensions.specifications}
      </Typography.Text>
    </div>
  );
};

export default DimensionsSection;


