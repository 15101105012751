import React, { useState } from "react";
import {
  Checkbox,
  Button,
  List,
  Typography,
  message,
  Divider,
  Input,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import CategoryGrid from "./CategoryGrid";
import Page from "../../layouts/Page";
import { useNavigate } from "react-router-dom";
import cover from "../../assets/categoires_astro/ask_astroguru_banner.jpeg";
import { categoryQuestions } from "./categoryQuestions";
import Footer from "../../layouts/Footer";
import { useSelector } from "react-redux";
import PoojaCarousel from "../pooja/PoojaCarousel";

function AstroGuruFind() {
  const navigate = useNavigate();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsJson, setQuestionsJson] = useState({});
  const [activeCategory, setActiveCategory] = useState("career");
  const [customQuestion, setCustomQuestion] = useState("");
  const { homeData } = useSelector((state) => state?.homeReducer);

  // Dynamic quePrice based on the number of selected questions
  const quePrice = selectedQuestions.length >= 4 ? 250 : 350;

  // Calculate savings for >= 4 questions
  const calculateSavings = () => {
    const extraQuestions = selectedQuestions.length - 4;
    if (extraQuestions >= 0) {
      return 400 + extraQuestions * 100;
    }
    return 0;
  };

  const discountMessage = (() => {
    const questionCount = selectedQuestions.length;

    if (questionCount === 0 || questionCount === 1 || questionCount === 2) {
      return ""; // No message for 1 or 2 questions
    } else if (questionCount === 3) {
      return "Add one more and get one free!";
    } else if (questionCount >= 4) {
      const savings = calculateSavings();
      return `Congratulations! You saved ₹${savings}`;
    } else {
      return "";
    }
  })();

  const handleSelection = (question, category = activeCategory) => {
    const isSelected = selectedQuestions.includes(question);
    let updatedQuestionsJson = { ...questionsJson };

    if (isSelected) {
      setSelectedQuestions(
        selectedQuestions?.filter((item) => item !== question)
      );
      updatedQuestionsJson[category] = updatedQuestionsJson[category]?.filter(
        (item) => item !== question
      );
    } else {
      setSelectedQuestions([...selectedQuestions, question]);
      if (!updatedQuestionsJson[category]) {
        updatedQuestionsJson[category] = [];
      }
      updatedQuestionsJson[category] = [
        ...updatedQuestionsJson[category],
        question,
      ];
    }
    if (updatedQuestionsJson[category]?.length === 0) {
      delete updatedQuestionsJson[category];
    }
    setQuestionsJson(updatedQuestionsJson);
  };

  const addCustomQuestion = () => {
    if (!customQuestion) return;
    handleSelection(customQuestion, "other");
    setCustomQuestion("");
  };

  const submitHandler = async () => {
    if (selectedQuestions.length === 0) {
      message.error("Select at least one question!");
      return;
    }
    navigate("/astro-guru/form", {
      state: {
        selectedQuestions,
        questionsJson,
        totalAmount: (selectedQuestions.length * quePrice).toFixed(2),
      },
    });
  };

  let banners = homeData.banner?.filter((item) => item.page === "astro_guru");
  let bannersData = banners?.map((item) => item.url);

  return (
    <Page>
      <PoojaCarousel
        product={{
          name: "",
          images: bannersData?.length > 0 ? bannersData : [cover],
        }}
        setBgBlur={() => {}}
      />

      <div className="bg-gray-50 min-h-screen">
        <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold text-center">
            Get Answers from our expert{" "}
            <span className="text-orange-500">AstroGuru</span>
          </h2>

          <Typography.Text type="secondary" className="mb-4 block">
            Get personalized remedies, puja, and gemstone recommendations from
            AstroGuru within 48 hours. Submit your questions, birth details, and
            contact info to receive solutions based on your kundali.
          </Typography.Text>

          <CategoryGrid
            setActiveCategory={setActiveCategory}
            activeCategory={activeCategory}
          />

          <div className="border p-2 mt-6 rounded-lg">
            <Typography.Text className="text-2xl">
              {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}
            </Typography.Text>
            <div className="grid grid-cols-1 gap-4 mt-2">
              {categoryQuestions[activeCategory].map((question, index) => (
                <div className="border rounded-lg" key={index}>
                  <Checkbox
                    onChange={() => handleSelection(question)}
                    className={`p-4 rounded-lg ${
                      selectedQuestions.includes(question)
                        ? "bg-orange-100"
                        : ""
                    }`}
                    checked={selectedQuestions.includes(question)}
                  >
                    {question}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>

          <Input
            className="mt-6"
            placeholder="Type your question here..."
            value={customQuestion}
            onChange={(e) => setCustomQuestion(e.target.value)}
            onPressEnter={addCustomQuestion}
            suffix={
              <Button type="link" onClick={addCustomQuestion}>
                Add
              </Button>
            }
          />

          <div className="mt-6 bg-gray-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold">
              Questions for Ask an AstroGuru
            </h3>
            <List
              dataSource={selectedQuestions}
              renderItem={(item) => (
                <List.Item
                  className="flex justify-between items-center"
                  actions={[
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-500">₹{quePrice}</span>
                      <CloseCircleOutlined
                        className="text-red-500"
                        onClick={() => handleSelection(item, "other")}
                      />
                    </div>,
                  ]}
                >
                  <p>{item}</p>
                </List.Item>
              )}
              footer={
                <div className="mt-4 space-y-2">
                  <div className="flex justify-between font-semibold text-lg">
                    <span>Total Amount:</span>
                    <span>
                      ₹{(selectedQuestions.length * quePrice).toFixed(2)}
                    </span>
                  </div>
                  <Typography.Text type="secondary">
                    {discountMessage}
                  </Typography.Text>
                </div>
              }
            />
          </div>

          <Footer />

          <Divider />
          <Divider />
          <div className="mt-6 absolute bottom-0 left-0 right-0 bg-white">
            {selectedQuestions.length >= 1 && (
              <Typography.Text className="text-center block font-bold text-orange-500 py-2">
                {discountMessage}
              </Typography.Text>
            )}

            <div
              className={`p-1 w-full ${
                selectedQuestions?.length === 0 ? "bg-gray-400" : "bg-green-500"
              } `}
            >
              <Button
                size="large"
                className="w-full border-none text-white font-medium "
                onClick={submitHandler}
                disabled={selectedQuestions?.length === 0}
              >
                Ask AstroGuru Expert (
                <span>₹{parseInt(selectedQuestions.length * quePrice)}</span>)
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default AstroGuruFind;
