import React, { useState } from "react";
import { Card, Typography } from "antd";
import avatar_men from '../assets/avatar_men.jpg'

const CustomerReviews = ({ title, reviews }) => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getDescription = (description, isExpanded) => {
    if (!isExpanded && description.length > 80) { // Adjust the character limit for two lines
      return `${description.substring(0, 80)}...`;
    }
    return description;
  };

  return (
    <div className="mt-8">
      <h3 className="text-xl font-medium mb-4">{title}</h3>
      <div className="space-y-4 border p-2 rounded-lg">
        {reviews.map((review, index) => {
          const isExpanded = expanded[index];
          const shouldShowReadMore = review.description.length > 100; // Adjust the character limit as needed

          return (
            <Card
              key={index}
              className="rounded-lg shadow-md p-4"
              bodyStyle={{ padding: 0 }}
            >
              <div className="flex items-center space-x-4 mb-2">
                <div className="flex-shrink-0">
                    <img
                      src={review.image || avatar_men}
                      alt={review.name}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                </div>
                <div>
                  <Typography.Text className="font-semibold text-sm">
                    {review.name}
                  </Typography.Text>
                    <div className="flex items-center">
                      <span className="text-yellow-500 text-xs">{'★'.repeat(5)}</span>
                    </div>
                </div>
              </div>
              <Typography.Paragraph className="text-sm text-gray-700 inline">
                {getDescription(review.description, isExpanded)}
              </Typography.Paragraph>
              {shouldShowReadMore && (
                <Typography.Text
                  className="text-blue-500 cursor-pointer text-xs inline"
                  onClick={() => toggleExpand(index)}
                >
                  {isExpanded ? "read less" : "read more"}
                </Typography.Text>
              )}
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default CustomerReviews;


