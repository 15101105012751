import { Select } from "antd";
import React, { useEffect, useState } from "react";

const CustomDatePicker = ({ onChange, className }: any) => {
  // Initialize state for the day, month, and year
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  // Generate arrays for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Example: Generate years from 1940 to the current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1940 + 1 },
    (_, i) => i + 1940
  );

  // Handle changing the date
  const handleDateChange = (e: any, type: any) => {
    const value = e;
    if (type === "day") setDay(value);
    else if (type === "month") setMonth(value);
    else if (type === "year") setYear(value);

    // Optional: Call an onChange prop function to pass the selected date to the parent component
    if (onChange) onChange({ day, month, year });
  };

  useEffect(() => {
    if (onChange) onChange({ day, month, year });   
  }, [day, month, year])
  

  return (
    <div className={className}>
      {/* <select value={day} onChange={(e) => handleDateChange(e, "day")}>
        <option disabled>Day</option>
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select> */}

      <Select
        className="w-[100px] mr-2"
        placeholder="Day"
        onChange={(e) => handleDateChange(e, "day")}
        options={days?.map((mn: any) => {
          return {
            value: mn,
            label: mn,
          };
        })}
      />

      {/* <select value={month} onChange={(e) => handleDateChange(e, 'month')}>
        <option disabled>Month</option>
        {months.map((month, index) => (
          <option className='bg-red-500' key={month} value={index + 1}>{month}</option>
        ))}
      </select> */}

      <Select
        className="w-[100px] mr-2"
        placeholder="Month"
        onChange={(e) => handleDateChange(e, "month")}
        options={months.map((mn: any) => {
          return {
            value: mn,
            label: mn,
          };
        })}
      />

      <Select
        className="w-[100px] mr-2"
        placeholder="Year"
        onChange={(e) => handleDateChange(e, "year")}
        options={years?.reverse()?.map((mn: any) => {
          return {
            value: mn,
            label: mn,
          };
        })}
      />

      {/* <select value={year} onChange={(e) => handleDateChange(e, "year")}>
        <option disabled>Year</option>
        {years?.reverse()?.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default CustomDatePicker;
