export const categoryQuestions = {
    career: [
      "I have alternate career opportunity, should I Consider? What is the remedy to achieve the desired results?",
      "Will I get a job opportunity abroad? What is the remedy to achieve the desired results?",
      "Is there a possibility of a promotion in my current job? What is the remedy to achieve the desired results?",
      "What are the chances of a career change being successful? What is the remedy to achieve the desired results?",
      "Should I pursue further education to advance my career? What is the remedy to achieve the desired results?",
      "I am facing continuous struggles in my career. When will I get relief ?",
      "Should I opt for job change? What is the remedy to achieve the desired results?",
      "When will I get salary hike ? What is the remedy to achieve the desired results?",
      "I am unemployed, when will I get a job ? What is the remedy to achieve the desired results?",
      "I lost my job, when will I get new job ? What is the remedy to achieve the desired results?",
    ],
    marriage: [
      "I am still single, will I get married? What is the remedy to achieve the desired results?",
      "When will I get married? What is the remedy to achieve the desired results?",
      "Arrange or Love marriage? What is the remedy to achieve the desired results?",
      "Do I have a separation or divorce? What is the remedy to avoid it?",
      "Will I marry intercast? What is the remedy to achieve the desired results?",
      "Will I marry inter-religion? What is the remedy to achieve the desired results?",
      "When my child will get married? What is the remedy to achieve the desired results?",
      "Will I settle abroad after marriage? What is the remedy to achieve the desired results?",
      "My spouse will be from India or Abroad? What is the remedy to achieve the desired results?",
      "How can I improve my relationship with my spouse? What is the remedy to achieve the desired results?",
      "Is there a chance for reconciliation after a breakup? What is the remedy to achieve the desired results?",
      "Will I have a happy and understanding married life? What is the remedy to achieve the desired results?",
      "Will our marriage be harmonious and prosperous? What is the remedy to achieve the desired results?",
    ],
    finance: [
      "What sort of investment suits me? What is the remedy to achieve the desired results?",
      "When I will be able to create wealthy life? What is the remedy to achieve the desired results?",
      "Will I be able to buy new house? What is the remedy to achieve the desired results?",
      "Do I have a luck in speculation? What is the remedy to achieve the desired results?",
      "Should I trade or invest? What is the remedy to achieve the desired returns?",
      "Will I be successful in Share Investment or Debt Instruments? What is the remedy to achieve the desired results?",
      "I am not able to sell my real estate. When will I able to sell it? What is the remedy to achieve the desired results?",
      "How can I improve my financial situation? What is the remedy to achieve the desired results?",
      "What is the best time to start a new business? What is the remedy to achieve the desired results?",
      "Will my investments yield good returns? What is the remedy to achieve the desired results?",
      "How can I manage my debts effectively? What is the remedy to achieve the desired results?",
      "Will I face any financial losses in the near future? What is the remedy to achieve the desired results?",
    ],
    love: [
      "Will the current relationship work? What is the remedy to achieve the desired results?",
      "Will I fall in love? What is the remedy to achieve the desired results?",
      "Will I again fall in love? What is the remedy to achieve the desired results?",
      "When will I fall in love? What is the remedy to achieve the desired results?",
      "Will I fall in love with same gender? ",
      "I am in long distance relationship, will it work? What is the remedy to achieve the desired results?",
      "Is this relationship with my current partner meant to last? What is the remedy to achieve the desired results?",
      "How can I attract love into my life? What is the remedy to achieve the desired results?",
      "Will my current relationship lead to marriage? What is the remedy to achieve the desired results?",
      "What can I do to strengthen my love life? What is the remedy to achieve the desired results?",
      "Will I find true love soon? What is the remedy to achieve the desired results?",
    ],
    childbirth: [
      "When will we be blessed with our first child? What is the remedy to achieve the desired results?",
      "My partner is not ready for the child. What is the remedy to make partner agree for child?",
      "What is the date of expected delivery? ",
      "Should we go for natural conceiving? What is the remedy to achieve natural conceiving?",
      "When my partner will be ready for child? What is the remedy to achieve the desired results?",
      "Should we go for surrogacy? What is the remedy to achieve the desired results?",
      "Should we adopt child ? What is the remedy to achieve the desired results?",
      "We are facing problems with pregnancy. What is the remedy to achieve the desired results?",
      "We are not able to conceive. What is the remedy to achieve the desired results?",
      "Our families are putting pressure but we are not able to get pregnant. What is the remedy to achieve the desired results?",
      "When will we have our first child? What is the remedy to achieve the desired results?",
      "Are there any astrological remedies for childbirth?",
      "Will our child be healthy and prosperous? What is the remedy to achieve the desired results?",
      "What can we do to ensure a smooth childbirth? What is the remedy to achieve the desired results?",
      "Is there a favorable time for conceiving? What is the remedy to achieve the desired results?",
    ],
    business: [
      "Which type of business will be suitable for me ? What is the remedy to achieve the desired results?",
      "Should I do business in India or Abroad ? What is the remedy to achieve the desired results?",
      "When should I start my business? What is the remedy to achieve the desired results?",
      "I am facing loss in business. What is the remedy to achieve the desired results?",
      "I am facing lot of problems / differences with my partner. Should I continue ?",
      "I am facing continuous legal problems in my business. What is the remedy to achieve the desired results?",
      "I am not able to raise funds for the business. What is the remedy to achieve the desired results?",
      "Should I raise money through debt or equity route ? What is the remedy to achieve the desired results?",
      "Should I expand my business? What is the remedy to achieve the desired results?",
      "Will I be able to expand my business? What is the remedy to achieve the desired results?",
      "Should I have foreign partner in business? What is the remedy to achieve the desired results?",
      "Is lady luck good for me in business? What is the remedy to achieve the desired results?",
      "Will my relatives & friends will help me in business? What is the remedy to achieve the desired results?",
      "Should I list my business in stock exchange? What is the remedy to achieve the desired results?",
      "Should I buy more inventory? What is the remedy to achieve the desired results?",
      "What is the future of my business? What is the remedy to achieve the desired results?",
      "Is this the right time to start a new business? What is the remedy to achieve the desired results?",
      "How can I grow my existing business? What is the remedy to achieve the desired results?",
      "Will my business venture be successful? What is the remedy to achieve the desired results?",
      "What are the potential challenges in my business? What is the remedy to achieve the desired results?",
      "Should I consider expanding my business? What is the remedy to achieve the desired results?",
    ],
    education: [
      "Will my child go abroad? What is the remedy to achieve the desired results?",
      "Will I score for higher studies in India? What is the remedy to achieve the desired results?",
      "Will I get a good college? What is the remedy to achieve the desired results?",
      "Will my son/ daughter will get college admission? What is the remedy to achieve the desired results?",
      "Will I succeed in abroad? What is the remedy to achieve the desired results?",
      "Will I get sponsorship for education in India? What is the remedy to achieve the desired results?",
      "Will I get scholarship for education abroad? What is the remedy to achieve the desired results?",
      "Will I get admission in good university? What is the remedy to achieve the desired results?",
      "Will I do higher studies? What is the remedy to achieve the desired results?",
      "Should I do higher studies? What is the remedy to achieve the desired results?",
      "Which education stream suits me? What is the remedy to achieve the desired results?",
      "I am failing in exams, what can I do? What is the remedy to achieve the desired results?",
      "How can I clear my exams? What is the remedy to achieve the desired results?",
      "I am not able to pass my examinations, when will I clear? What is the remedy to achieve the desired results?",
      "Will I clear my exam? What is the remedy to achieve the desired results?",
      "I am stressed about exam. What is the remedy to achieve the desired results?",
      "Should I allow my child to study as per wish? What is the remedy to achieve the desired results?",
      "What should I choose my child’s education? What is the remedy to achieve the desired results?",
      "How my child will perform in education? What is the remedy to achieve the desired results?",
      "Will I get admission to my desired college/university? What is the remedy to achieve the desired results?",
      "How can I excel in my studies? What is the remedy to achieve the desired results?",
      "Is this the right time to pursue higher education? What is the remedy to achieve the desired results?",
      "Will I be successful in competitive exams? What is the remedy to achieve the desired results?",
      "What are the chances of studying abroad? What is the remedy to achieve the desired results?",
    ],
    travel: [
      "I am not getting Visa. What is the remedy to achieve the desired results?",
      "I don’t have a passport. What is the remedy to achieve the desired results?",
      "How can I get my passport. What is the remedy to achieve the desired results?",
      "Should I settle in coastal place? What is the remedy to achieve the desired results?",
      "Should I travel to mountains or beach? What is the remedy to achieve the desired results?",
      "Is flight travel safe for me? What is the remedy to achieve the desired results?",
      "Is water travel safe for me? What is the remedy to achieve the desired results?",
      "Is road travel safe for me? What is the remedy to achieve the desired results?",
      "Will I ever travel abroad? What is the remedy to achieve the desired results?",
      "Will my child go abroad? What is the remedy to achieve the desired results?",
      "Will my business travel be successful? What is the remedy to achieve the desired results?",
      "My Visa application is stuck. What is the remedy to achieve the desired results?",
      "Is it a good time to travel abroad?",
      "Will my travel plans be successful? What is the remedy to achieve the desired results?",
      "Are there any astrological remedies for safe travel? What is the remedy to achieve the desired results?",
      "Will I get a job opportunity abroad? What is the remedy to achieve the desired results?",
      "Is relocating to a new place favorable for me? What is the remedy to achieve the desired results?",
    ],
    health: [
      "When will my health improve? What is the remedy to achieve the desired results?",
      "My parents are suffering from long term sickness. What is the remedy to achieve the desired results?",
      "Will I suffer from heart attack? What is the remedy to achieve the desired results?",
      "How can I die? What is the remedy to achieve the desired results?",
      "Will I get diabetes? What is the remedy to achieve the desired results?",
      "Will I have stomach issues? What is the remedy to achieve the desired results?",
      "I am having multiple health issues. What is the remedy to achieve the desired results?",
      "My wife health is not doing well. What is the remedy to achieve the desired results?",
      "My partner health is very poor for long time. What is the remedy to achieve the desired results?",
      "Will I suffer from memory loss? What is the remedy to achieve the desired results?",
      "How can I take care of my health? What is the remedy to achieve the desired results?",
      "How can I improve my family’s health? What is the remedy to achieve the desired results?",
      "What to do to improve family’s health? What is the remedy to achieve the desired results?",
      "What can I do to achieve longevity? What is the remedy to achieve the desired results?",
      "Should I take health insurance? What is the remedy to achieve the desired results?",
      "Is there any major expense coming up for health? What is the remedy to achieve the desired results?",
      "How can I improve my health? What is the remedy to achieve the desired results?",
      "Are there any health issues I should be concerned about? What is the remedy to achieve the desired results?",
      "What are the remedies for my ongoing health problems? What is the remedy to achieve the desired results?",
      "Will I recover from my current illness soon? What is the remedy to achieve the desired results?",
      "How can I maintain good health in the future? What is the remedy to achieve the desired results?",
    ],
    legal: [
      "I am stuck in court cases. What is the remedy to achieve the desired results?",
      "Will I win my court case? What is the remedy to achieve the desired results?",
      "Will I be able to bear the cost of legal fees? What is the remedy to achieve the desired results?",
      "Do I have to wait for success in legal cases? What is the remedy to achieve the desired results?",
      "Should I fight case in court or settle? What is the remedy to achieve the desired results?",
      "I have an offer for settlement. Should I fight or settle? What is the remedy to achieve the desired results?",
      "I am stuck in a police case. What is the remedy to achieve the desired results?",
      "My property/ plot is stuck in a fraud case. What is the remedy to achieve the desired results?",
      "Somebody did a fraud with me. What is the remedy to achieve the desired results?",
      "Will my ongoing legal case be resolved in my favor? What is the remedy to achieve the desired results?",
      "What are the chances of winning the lawsuit? What is the remedy to achieve the desired results?",
      "Is there a risk of legal troubles in the future? What is the remedy to achieve the desired results?",
      "How can I avoid legal issues? What is the remedy to achieve the desired results?",
      "What remedies can help with my legal problems? What is the remedy to achieve the desired results?",
    ],
  };
  