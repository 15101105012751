import { Typography } from "antd";

const AdditionalKavachItems = ({ items,title }) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-medium text-center text-orange-600">
        {title}
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 mt-2">
        {items.map((item, index) => (
          <div
            key={index}
            className=" p-2 flex flex-col justify-between h-full bg-gradient-to-r from-orange-300 via-orange-200 to-yellow-100 shadow-lg rounded-lg "
            hoverable
          >
            <div className="flex items-center ">
              {item.link ? (
                <img src={item.link} alt="icon" className="w-10 h-10 rounded-full mr-2 mb-2" />
              ) : (
                <span className="text-2xl mr-3">{item.icon}</span>
              )}

              <Typography.Text className="mb-0 font-bold block text-gray-800">
                {item.name}
              </Typography.Text>
            </div>
            <Typography.Text className="text-gray-700">
              {item.how_to_use}
            </Typography.Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalKavachItems;
