export default function ChatWithUs({ title }: any) {
  const num = "+919599224564";
return <></>
  return (
    <div
      onClick={() => {
        window.open(`https://wa.me/${num}/?text=${title || ""}`, "_blank");
      }}
      className="fixed bottom-[60px] right-4 bg-white z-[1001] px-4 py-2 rounded shadow-lg"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        border: "1px solid rgba(0, 0, 0, 0.25)",
      }}
    >
      <button className="text-green-500 font-bold text-lg">Chat With us</button>
    </div>
  );
}
