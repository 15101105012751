
export const siteConfig:any = {
  name: "DevPunya",
  description: "",
  mobileNumber: "7737998057",
  footer: [
    { name: "Home", href: "/" },
    { name: "About", href: "#" },
    { name: "Terms & Conditions", href: "#" },
    { name: "Shipping & Return Policy", href: "#" },
    { name: "Privacy Policy", href: "#" },
    { name: "FAQ", href: "#" },
  ],
  global:{
    currency:"₹"
  },
  upiId:`upi://pay?pa=khelgully@kotak&pn=FreshFusion&cu=INR`,
  social:{
    instagram:"https://instagram.com/freshfusion_fashionwear?igshid=MzRlODBiNWFlZA==",
    whatsapp:`https://wa.me/917503291508?text=urlencodedtext`
  }
}