import { Select, Typography } from "antd";
import React, { useEffect, useState } from "react";

const CustomTimePicker = ({ onChange, className }) => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [period, setPeriod] = useState("");

  // Generate options for hours and minutes
  const hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const periods = ["AM", "PM"];

  // Handle changing the time
  const handleTimeChange = (value, type) => {
    if (type === "hour") setHour(value);
    else if (type === "minute") setMinute(value);
    else if (type === "period") setPeriod(value);

    // Optional: Call an onChange prop function to pass the selected time to the parent component
  };


  useEffect(() => {
    if (onChange) onChange({ hour, minute, period });
  }, [hour, minute, period])
  
  return (
    <div className={className}>
      {/* <select value={hour} onChange={(e) => handleTimeChange(e.target.value, 'hour')}>
        <option>Hour</option>
        {hours.map(hour => (
          <option key={hour} value={hour}>{hour}</option>
        ))}
      </select> */}

      <Select
        className="w-[28%] mr-2"
        placeholder="Hour"
        onChange={(e) => handleTimeChange(e, "hour")}
        options={hours?.map((val) => {
          return {
            value: val,
            label: val,
          };
        })}
      />
      <Typography.Text>:</Typography.Text>
      <Select
        className="w-[28%] mr-2 ml-2"
        placeholder="Minute"
        onChange={(e) => handleTimeChange(e, "minute")}
        options={minutes?.map((val) => {
          return {
            value: val,
            label: val,
          };
        })}
      />
      <Typography.Text>:</Typography.Text>
      <Select
        className="w-[28%] ml-1"
        placeholder="AM/PM"
        onChange={(e) => handleTimeChange(e, "period")}
        options={periods?.map((val) => {
          return {
            value: val,
            label: val,
          };
        })}
      />

      {/* <select
        value={minute}
        onChange={(e) => handleTimeChange(e.target.value, "minute")}
      >
        <option>Minute</option>
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </select> */}
      {/* <select
        value={period}
        onChange={(e) => handleTimeChange(e.target.value, "period")}
      >
        <option>AM/PM</option>
        {periods.map((period) => (
          <option key={period} value={period}>
            {period}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default CustomTimePicker;
