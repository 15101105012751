import React, { useState, useEffect } from "react";
import { Input, Empty, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import AbhimantritProductGrid from "../abhimantritProduct/AbhiMantritProductGrid";

const GemstoneList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [gemstones, setGemstones] = useState(null);
  const [loading, setLoading] = useState(true);

  const getGemstones = async () => {
    const res = await getRequest(`/noauth-api/v1/gemstone/getProductListing`);
    setGemstones(res?.results);
    setLoading(false);
  };

  useEffect(() => {
    getGemstones();
  }, []);

  if (loading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  const filteredGemstones = gemstones
    ?.filter((gemstone) =>
      gemstone?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.rank - b.rank); // Sort gemstones based on rank

  return (
    <Page>
      <div className="relative min-h-screen bg-white px-2">
        <h1 className="text-3xl font-bold text-center mb-4">
          Gemstone Products
        </h1>
        <p className="text-center text-gray-500 mb-6">
          Shop Best Online Astrology Products And Services
        </p>
        <div className="relative flex justify-center mb-8 z-10">
          <Input
            className="max-w-lg w-full"
            placeholder="Search Gemstone Products"
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {loading ? (
          <div className="relative flex justify-center items-center h-full z-10">
            <Loader />
          </div>
        ) : filteredGemstones.length > 0 ? (
          <AbhimantritProductGrid
            type="gemstones"
            products={filteredGemstones}
          />
        ) : (
          <div className="relative flex justify-center items-center h-full z-10">
            <Empty description="No products found" />
          </div>
        )}
      </div>
    </Page>
  );
};

export default GemstoneList;
