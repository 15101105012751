import React, { useState, useEffect } from "react";
import { Input, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page";
import AbhimantritProductGrid from "./AbhiMantritProductGrid";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
// import left_bg_svg from "../../assets/left_bg_svg.png";
// import right_bg_svg from "../../assets/right_bg_svg.png";

const AbhimantritProducts = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await getRequest(`/noauth-api/v1/abhimantrit_product/getProductListing`);
      setProducts(res?.results || []);
      setLoading(false);
    };

    fetchProducts();
  }, []);


  const filteredGemstones = products
  ?.filter((gemstone) =>
    gemstone?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.rank - b.rank); // Sort gemstones based on rank


  return (
    <Page>
      <div className="relative min-h-screen bg-white px-2">
        {/* Background images */}
        {/* <img
          src={right_bg_svg}
          alt="Left Background"
          className="absolute top-0 left-0 w-1/3 h-auto"
          style={{ zIndex: 1, opacity: 0.5 }}
        />
        <img
          src={left_bg_svg}
          alt="Right Background"
          className="absolute top-0 right-0 w-1/3 h-auto"
          style={{ zIndex: 1, opacity: 0.5 }}
        /> */}

        <h1 className="relative text-3xl font-bold text-center mb-4 z-10">
          Abhimantrit Products
        </h1>
        <p className="relative text-center text-gray-500 mb-6 z-10">
          Discover our exclusive range of Abhimantrit products
        </p>

        <div className="relative flex justify-center mb-8 z-10">
          <Input
            className="max-w-lg w-full"
            placeholder="Search Abhimantrit Product"
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {loading ? (
          <div className="relative flex justify-center items-center h-full z-10">
            <Loader />
          </div>
        ) : filteredGemstones.length > 0 ? (
          <AbhimantritProductGrid products={filteredGemstones} />
        ) : (
          <div className="relative flex justify-center items-center h-full z-10">
            <Empty description="No products found" />
          </div>
        )}
      </div>
    </Page>
  );
};

export default AbhimantritProducts;
