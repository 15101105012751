import { SET_ADDRESSES, SET_CART_COUNT, SET_COLORS, SET_PRODUCTS } from "../types";

export const setProducts = (products: any[]) => ({
  type: SET_PRODUCTS,
  payload: products,
});

export const setColorsMap = (colors: any[]) => ({
  type: SET_COLORS,
  payload: colors,
});


export const setAddresses = (address: any[]) => ({
  type: SET_ADDRESSES,
  payload: address,
});


export const setCartCount = (cart: any[]) => ({
  type: SET_CART_COUNT,
  payload: cart,
});

