import React from "react";
import { Helmet } from "react-helmet-async";
interface SeoHelmetProps {
  title?: string;
  description?: string;
  canonical?: string;
  keywords?: string;
}
export default function SeoHelmet({ title, description,keywords }: SeoHelmetProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" property="og:title" content={title} />
      <link rel="canonical" href={window.location.pathname} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}
